.course-folder {
  margin-bottom: 20px;
}

.html-viewer {
  margin-top: 20px;
  /* margin:auto; */
  margin-left: 20px;
  margin-right: 20px;
  padding: 40px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.3);
}

.home {
  padding: 20px;
}

.course {
  margin-top: 50px;
  padding-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.3);
}

.course-name {
  background-color: bisque;
  line-height: 50px;
  padding: auto;
  margin: auto;
  text-align: center;
}
.course-item {
  margin-left: 20px;
}

.course-folder {
  margin: 30px;
}

.course-link {
  display: block;
  margin-left: 20px;
  margin-top: 5px;
}
/* App.css (or your CSS file) */
.site-layout-content {
  flex-grow: 1; /* Allow content to grow and fill available space */
  padding: 20px; /* Add padding to content */
  margin-bottom: 60px; /* Ensure enough space for the footer */
}

/* Apply a flexbox layout to the main layout */
.layout {
  display: flex;
  flex-direction: column; /* Stack header, content, and footer vertically */
  min-height: 100vh; 
}
.no-results-container {
  display: flex;
  justify-content: center; 
  align-items: center; /* Center vertically */
  height: calc(100vh - 163px); /* Adjust the height as needed to position it on the page */

}
.highlight-target {
  background-color: yellow; /* Or any contrasting color */
  transition: background-color 0.3s; /* Smooth transition for color change */
}
